@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.App {
  margin: auto;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  max-width: 50em;
  font-size: 18px;
}

h1.Name {
  text-align: center;
  margin-bottom: .2em;
}

p.Contact {
  text-align: center;
}

hr {
  margin-top: 0;
}

h2 {
  margin-bottom: .3em;
}

h3 {
  margin-top: .3em;
  margin-bottom: .2em;
}

h4 {
  margin-top: 0;
  margin-bottom: .4em;
}

div.Section {
  margin-bottom: 2em;
}

p.body {
  text-align: justify;
}

span.clickable {
  cursor: pointer;
}

span.bp3-icon {
  margin-left: .5em;
  margin-bottom: .2em;
}

div.bp3-drawer.bp3-position-right {
  width: 50em;
  max-width: 50%;

  @media (max-width: 50em) {
    width: 100%;
    max-width: 100%;
  }
}

div.bp3-dialog-body {
  text-align: justify;
  font-size: 16px;
  line-height: 26px;

  img.artefact {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  img.artefact-portrait {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  p.caption {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    width: 85%;
    margin: auto;
    margin-bottom: 1em;
  }

  p.section {
      font-weight: bold;
      font-size: 120%;
      margin-top: 1em;
  }
}

// usr4002a
div.bp3-dialog {
  padding-bottom: 0;
  text-align: justify;
  max-width: 90%;
  width: 700px;  // was 500px, but felt too narrow

  p:last-child {
    margin-bottom: 0px;
  }
}

// Print settings

span.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: inherit;
  }
}
